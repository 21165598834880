import VueRouter, { RouteConfig } from 'vue-router'

import Vue from 'vue'
import userManager from "@/services/userManager"

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: "/delete_user",
    component: () => import('../views/DeleteUser.vue'),
    meta: {
      allowGuest: true,
    },
  },
  {
    path: "/privacy",
    component: () => import('../views/Privacy.vue'),
    meta: {
      allowGuest: true,
    },
  },
  {
    path: "/terms",
    component: () => import('../views/Terms.vue'),
    meta: {
      allowGuest: true,
    },
  },
  {
    path: '',
    redirect: "/app"
  },
  {
    path: "/privacy_policy",
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: "/terms_and_conditions",
    component: () => import('../views/TermsAndConditions.vue'),
  },
  {
    path: "/faq",
    component: () => import('../views/Faq.vue'),
  },
  {
    path: "/ext/vnpay_success",
    component: () => import('../views/ext/VnpaySuccess.vue'),
  },
  {
    path: "/ext/vnpay_error",
    component: () => import('../views/ext/VnpayError.vue'),
  },
  {
    path: "/ext/momo_success",
    component: () => import('../views/ext/MomoSuccess.vue'),
  },
  {
    path: "/ext/momo_error",
    component: () => import('../views/ext/MomoError.vue'),
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (userInfo) {
        next("/app")
      } else {
        next()
      }
    },
  },
  {
    path: '/signup',
    component: () => import('../views/Signup.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (userInfo) {
        next("/app")
      } else {
        next()
      }
    },
  },
  {
    path: '/forgot_password',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset_password',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/active_user',
    component: () => import('../views/ActiveUser.vue'),
  },
  {
    path: '/app',
    component: () => import('../views/app/Layout.vue'),
    beforeEnter(from, to, next) {
      if (to?.meta?.allowGuest || from?.meta?.allowGuest || to?.path === "/") {
        next()
        return
      }
      console.log({
        from,
        to,
      })
      const userInfo = userManager.getUserInfo()
      if (!userInfo) {
        next("/login")
      } else {
        next()
      }
    },
    children: [
      {
        path: "",
        redirect: "index",
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "index",
        component: () => import('../views/app/Index.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "action_logs",
        component: () => import('../views/app/ActionLogs.vue'),
      },
      {
        path: "charger_vendors",
        component: () => import('../views/app/ChargerVendors.vue'),
      },
      {
        path: "cars",
        component: () => import('../views/app/Cars.vue'),
      },
      {
        path: "tags",
        component: () => import('../views/app/Tags.vue'),
      },
      {
        path: "charging_details/:id/:connectorId",
        component: () => import('../views/app/ChargingDetails.vue'),
      },
      {
        path: "charging_details_m/:id/:connectorId",
        component: () => import('../views/app/ChargingDetailsM.vue'),
      },
      {
        path: "charging_sessions",
        component: () => import('../views/app/ChargingSessions.vue'),
      },
      {
        path: "plugged_charging_sessions",
        component: () => import('../views/app/PluggedChargingSessions.vue'),
      },
      {
        path: "charging_sessions/:id",
        component: () => import('../views/app/ChargingDetailsM.vue'),
      },
      {
        path: "charging_sessions_m",
        component: () => import('../views/app/ChargingSessionsM.vue'),
      },
      {
        path: "error_stations",
        component: () => import('../views/app/ChargingStationsError.vue'),
      },
      {
        path: "companion",
        component: () => import('../views/app/Companion.vue'),
      },
      {
        path: "promotional_news",
        component: () => import('../views/app/PromotionalNews.vue'),
      },
      // {
      //   path: "promotion_moneys",
      //   component: () => import('../views/app/PromotionMoneys.vue'),
      // },
      {
        path: "licenses",
        component: () => import('../views/app/Licenses.vue'),
      },
      {
        path: "stations_status",
        component: () => import('../views/app/ChargingStationsStatus.vue'),
      },
      {
        path: "charging_locations",
        component: () => import('../views/app/ChargingLocations.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "charging_locations_m",
        component: () => import('../views/app/ChargingLocationsM.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "charging_stations",
        component: () => import('../views/app/ChargingStations.vue'),
      },
      {
        path: "charging_stations_m",
        component: () => import('../views/app/ChargingStationsM.vue'),
      },
      {
        path: "charging_stations/:id/:connectorId",
        component: () => import('../views/app/ChargingStationConnectorDetails.vue'),
      },
      {
        path: "charging_stations_m/:id/:connectorId",
        component: () => import('../views/app/ChargingStationConnectorDetailsM.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "smart_sockets",
        component: () => import('../views/app/SmartSockets.vue'),
      },
      {
        path: "smart_socket_sessions",
        component: () => import('../views/app/SmartSocketSessions.vue'),
      },
      {
        path: "ocpp_autocharge_found_vehicles",
        component: () => import('../views/app/OcppAutochargeFoundVehicles.vue'),
      },
      {
        path: "ocpp_logs",
        component: () => import('../views/app/OcppLogs.vue'),
      },
      {
        path: "statistics",
        component: () => import('../views/app/Statistics.vue'),
      },
      {
        path: "profile",
        component: () => import('../views/app/Profile.vue'),
      },
      {
        path: "retailers",
        component: () => import('../views/app/Retailers.vue'),
      },
      {
        path: "cashback",
        component: () => import('../views/app/Cashback.vue'),
      },
      {
        path: "cashback/:id",
        component: () => import('../views/app/CashbackDetail.vue'),
      },
      {
        path: "charging_memberships",
        component: () => import('../views/app/ChargingMembership.vue'),
      },
      {
        path: "charging_memberships_history",
        component: () => import('../views/app/ChargingMembershipHistory.vue'),
      },
      {
        path: "promotions",
        component: () => import('../views/app/Promotions.vue'),
      },
      {
        path: "retailer/:id",
        component: () => import('../views/app/RetailerDetail.vue'),
      },
      {
        path: "settings",
        component: () => import('../views/app/Settings.vue'),
        children: [
          {
            path: "",
            redirect: "time_zone"
          },
          {
            path: "payment_methods",
            component: () => import('../views/app/settings/PaymentMethods.vue'),
          },
          {
            path: "payment_transactions",
            component: () => import('../views/app/settings/PaymentTransactions.vue'),
          },
          {
            path: "payment_transactions_m",
            component: () => import('../views/app/settings/PaymentTransactionsM.vue'),
          },
          {
            path: "payment_exchange_ratio",
            component: () => import('../views/app/settings/PaymentExchangeRatio.vue'),
          },
          {
            path: "time_zone",
            component: () => import('../views/app/settings/TimeZone.vue'),
          },
          {
            path: "notify_to_users",
            component: () => import('../views/app/settings/NotifyToUsers.vue'),
          },
          {
            path: "terms_and_conditions",
            component: () => import('../views/app/settings/TermsAndConditions.vue'),
          },
          {
            path: "privacy_policy",
            component: () => import('../views/app/settings/PrivacyPolicy.vue'),
          },
          {
            path: "faq",
            component: () => import('../views/app/settings/Faq.vue'),
          },
          {
            path: "mail",
            component: () => import('../views/app/settings/Mail.vue'),
          },
        ],
      },
      {
        path: "tenants",
        component: () => import('../views/app/Tenants.vue'),
      },
      {
        path: "users",
        component: () => import('../views/app/Users.vue'),
      },
      {
        path: "my_charger/home_m",
        component: () => import('../views/app/my_charger/HomeM.vue'),
      },
      {
        path: "my_charger/login_charger_m",
        component: () => import('../views/app/my_charger/LoginChargerM.vue'),
      },
      {
        path: "my_charger/scanning_qr_code_m",
        component: () => import('../views/app/my_charger/ScanQrCodeM.vue'),
      },
      {
        path: "my_charger/charging_stations_m/:id/:connectorId",
        component: () => import('../views/app/my_charger/ChargingDetailM.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "my_charger/charging_session_history_m/:id/:connectorId",
        component: () => import('../views/app/my_charger/ChargingSessionHistory.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "maps",
        component: () => import('../views/app/Maps.vue'),
      },
      {
        path: "my_charger/charging_config_m/:id/:connectorId",
        component: () => import('../views/app/my_charger/ChargingConfig.vue'),
        meta: {
          allowGuest: true,
        },
      },
      {
        path: "site_area",
        component: () => import('../views/app/SiteArea.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  base: "/",
  routes
})

export default router
